import { useState, useEffect, useRef } from "react";
import cx from "clsx";
import { useEvent } from "react-use-event-hook";
import styles from "./carousel.module.css";

// #region consts
const browserSrcs = [
  { alt: "Arc", src: "arc" },
  { alt: "Brave", src: "brave" },
  { alt: "Chrome", src: "chrome" },
  { alt: "Edge", src: "edge" },
  { alt: "Edge (EdgeHTML)", src: "edgeHTML" },
  { alt: "Firefox", src: "firefox" },
  { alt: "Internet Explorer", src: "ie" },
  { alt: "Netscape", src: "netscape" },
  { alt: "Opera", src: "opera" },
  { alt: "Safari", src: "safari" },
  { alt: "Samsung Internet", src: "samsung" },
  { alt: "UC", src: "uc" },
  { alt: "Vivaldi", src: "vivaldi" },
];
const browserSrcsInds = browserSrcs.length - 1;

const randomBetweenButExcluding = (...excluding: number[]): number => {
  const rand = Math.round(Math.random() * browserSrcsInds);

  return excluding.includes(rand)
    ? randomBetweenButExcluding(...excluding)
    : rand;
};

function getImages(...included: number[]) {
  const image1Ind = included[0] ?? randomBetweenButExcluding(...included);
  const image2Ind =
    included[1] ?? randomBetweenButExcluding(image1Ind, ...included);
  const image3Ind =
    included[2] ?? randomBetweenButExcluding(image1Ind, image2Ind, ...included);
  const image4Ind =
    included[3] ??
    randomBetweenButExcluding(image1Ind, image2Ind, image3Ind, ...included);
  const image5Ind =
    included[4] ??
    randomBetweenButExcluding(
      image1Ind,
      image2Ind,
      image3Ind,
      image4Ind,
      ...included
    );
  const image6Ind =
    included[5] ??
    randomBetweenButExcluding(
      image1Ind,
      image2Ind,
      image3Ind,
      image4Ind,
      image5Ind,
      ...included
    );
  const image7Ind =
    included[6] ??
    randomBetweenButExcluding(
      image1Ind,
      image2Ind,
      image3Ind,
      image4Ind,
      image5Ind,
      image6Ind,
      ...included
    );
  const image8Ind =
    included[7] ??
    randomBetweenButExcluding(
      image1Ind,
      image2Ind,
      image3Ind,
      image4Ind,
      image5Ind,
      image6Ind,
      image7Ind,
      ...included
    );

  return [
    image1Ind,
    image2Ind,
    image3Ind,
    image4Ind,
    image5Ind,
    image6Ind,
    image7Ind,
    image8Ind,
  ];
}
// #endregion

export default function Carousel({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<number[]>([]);
  const [viewingLast, setViewingLast] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(0);
  const transitionRef = useRef<(value: unknown) => void>();
  const onTransitionEnd = useEvent(() => {
    setTimeout(() => transitionRef.current?.(null), 100);
  });

  useEffect(() => {
    const included = viewingLast
      ? new Array(4).fill(undefined).concat(state.slice(4, 8))
      : state.slice(0, 4);

    setState(getImages(...included));
  }, [lastUpdated]);

  useEffect(() => {
    if (lastUpdated) {
      setViewingLast(!viewingLast);
    }
  }, [state]);

  useEffect(() => {
    let to: ReturnType<typeof setTimeout>;

    (function c() {
      to = setTimeout(async () => {
        setLastUpdated(Date.now());
        await new Promise((r) => (transitionRef.current = r));
        c();
      }, 5000);
    })();

    return () => clearTimeout(to);
  }, []);

  return (
    <div className={styles.carousel}>
      {children}
      <ul
        aria-label="List of Browsers"
        role="list"
        className={styles.browserList}
        onTransitionEnd={onTransitionEnd}
      >
        {browserSrcs.map(({ alt, src }, i) => {
          const col = state.findIndex((x) => x === i);
          const isActive = (viewingLast ? col >= 4 : col < 4) && col > -1;

          return (
            <li
              className={cx(styles.item, isActive && styles.item_active)}
              style={{ "--col": (col % 4) + 1 } as any}
              key={alt}
            >
              <img
                alt={alt}
                src={`/img/browsers/${src}.svg`}
                title={alt}
                className={styles.logo}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
